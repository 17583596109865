import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://tvschedule.familychannel.org/api/',
    timeout: 50000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*'
    }
});
instance.interceptors.response.use(response => {
    return response;
  }, error => {   
    //console.log(error.response)   
   if (error.response.status === 401) {
        //alert(error.response.data.error)
   }
   throw error;
//    return error.response.data.error;
  });

export default instance;