<template>    
	<div class="" role="tablist">
		<b-card no-body 
			class="mb-1 accordian--item" 
			v-for="(item, indx) of programsData" 
			:key="indx" 
			:data-starttime="item.start_timestr"
			:data-endtime="item.end_timestr"
			>
			<b-card-header header-tag="header" class="p-1" >
				<strong>{{ item.start_time }} - {{ item.end_time }} IST</strong>
				<h4 class="show-name">{{ item.title }}</h4>
			</b-card-header>
			
			<b-collapse v-model="collapses[indx]['show']" >
				<b-card-body>
					<b-row>
						<b-col sm="8">
							<b-card-text v-text="item.content"></b-card-text>
						</b-col>
						<b-col sm="4" v-if="(item.image !== null && item.image !== null)">
							<b-card-text>
								<img :alt="item.title" :src="item.image" class="img-thumbnail" />
							</b-card-text>
						</b-col>
					</b-row>
				</b-card-body>
			</b-collapse>
		</b-card>
	</div>        
</template>

<script>
import $ from 'jquery'
export default {
	name: 'Programs',
	props: {
		content: Array,
		currentTime: Number,
		currentDate: String
	},
    data() {
		return {	
			collapses: [],		
			programsData: [],
			currenttime: '',
			isVisible: true,
			showCollapse: true,
			todayDate: ''
		}
    },
	methods: {
		openAll() {
			this.collapses.forEach(collapse => {
                collapse.show = true
            })				
		},
		programsCollapse() {
			this.collapses = [];
			this.programsData.forEach(item=>{
				this.collapses.push({show: false});
			});
			this.openAll();
		},
		scrollToProgram() {
			if(this.todayDate != this.getDate()) {
				return;
			}
			let match = false;			
			document.querySelectorAll('.accordian--item').forEach(item=>{
				let startTime = Number(item.dataset.starttime);
				let endTime = Number(item.dataset.endtime);
				
				if(!match && (this.currenttime >= startTime && this.currenttime <= endTime)) {console.log(item.offsetTop)
					match = true;
					setTimeout(()=>{
						$('html, body').animate({
							// scrollTop: $('[data-starttime="1629916200"]').position().top + 270
							scrollTop: item.offsetTop + 270
						}, 1000);
						// $('.listing-wrapper').animate({scrollTop: item.offsetTop }, "slow");
					}, 1500);
				}
			})
		},
		pointerToTop(){
			window.scrollTo(0, 0);
		},
		getDate(){
			return new Date().toISOString().slice(0, 10)
		}
	},
	updated: function(){
		this.scrollToProgram();		
	},
	mounted: function(){
		//this.pointerToTop()
		this.programsData = this.$props.content;
		this.currenttime = this.$props.currentTime;
		this.programsCollapse();
		this.todayDate = this.$props.currentDate;
	},
	watch: {
        "$props.content"(){
			//this.pointerToTop()
            this.programsData = this.$props.content;
			this.currenttime = this.$props.currentTime;
			this.programsCollapse();
			this.todayDate = this.$props.currentDate;
        },
    }
}
</script>