<template>
	<div id="app">
		<vue-particles
			color="#dedede"
			:particleOpacity="0.7"
			:particlesNumber="100"
			shapeType="circle"
			:particleSize="4"
			linesColor="#dedede"
			:linesWidth="1"
			:lineLinked="true"
			:lineOpacity="0.4"
			:linesDistance="150"
			:moveSpeed="3"
			:hoverEffect="true"
			hoverMode="grab"
			:clickEffect="true"
			clickMode="push"
		>
		</vue-particles>
		<Header />
		<DateSection />
	</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Header from './components/Header.vue'
import DateSection from './components/DateSection.vue'


export default {
  name: 'App',
  components: {
    Header,
	DateSection
  }
}
</script>

<style>
#particles-js{
	position: fixed;
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: #000;
	/* background-image: url(''); */
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
</style>