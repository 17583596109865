<template>
    <div class="selection-section" data-module-id="shared-schedule">        
        <div class="date-options newstyle">
            <div class="container">
                <div class="date-picker-wrapper">
                    <div class="date-picker">
                        <div class="date-carousel">                
                            <carousel 
                                :autoplay="autoplay" 
                                :nav="nav" 
                                :navText="navText"   
                                :responsive="responsive"   
                                :dots="dots"    
                                :margin="1"     
                                v-if="owlActive"
                                >
                                <div 
                                    class="date-item" 
                                    :class="(v.timestamps === currentDate) ? 'current selected' : ''" v-for="(v, i) of allDates" 
                                    :key="i" 
                                    @click="showevents(v.timestamps)">                                    
                                    <strong>{{ v.month }} {{v.date}}</strong>
                                    <span class="day">{{v.day}}</span>
                                </div>
                            </carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="programs-wrapper">
                <bounce-loader :color="`#868e96`" :loading="loading"></bounce-loader>
                <div class="listing-wrapper mt-3">                
                    <Programs :content="programs" :currentTime="currentTime" :currentDate="todayDate" v-if="programs.length > 0"/>
                </div>
            </div>
        </div>
    </div>    
</template>

<style >
    .programs-wrapper{position:relative;}
    .programs-wrapper .v-spinner{position: absolute; background: rgba(0,0,0,.3); left: 0; top: 0; right: 0; bottom: 0; z-index: 2;}
    .programs-wrapper .v-spinner > .v-bounce.v-bounce1{ position: absolute !important; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); z-index: 2; width: 60px; height: 60px;}
    .date-picker-wrapper .owl-theme .owl-nav [class*='owl-']{color: #000; background: none; font-size: 23px;}
    .date-picker-wrapper .owl-theme .owl-nav{margin: 0;}
    .listing-wrapper .card-header{display: flex; padding: 16px 50px 16px 16px !important; align-items: center; position: relative;}
    .listing-wrapper .card-header:before{
        position: absolute; right: 20px;content: "\f078"; top: 50%; transform: translateY(-50%); font-size: 17px;font-family: "Font Awesome 5 Free"; font-weight: 900; color: rgba(0, 0, 0, 0.6);
    }
    .listing-wrapper .card-header strong{display: inline-block; width: 200px; text-align: right;font-size: 16px; font-weight: 400;}
    .listing-wrapper .card-header h4.show-name{margin: 0; padding: 0 0 0 16px; font-size: 16px; font-weight: 700;}
    .listing-wrapper .card-body{padding-left: 60px;}
    .listing-wrapper .card-header.not-collapsed:before{transform: translateY(-50%) rotate(-90deg);}
    .selection-section > .container{height: calc(100vh - 206px);}
    .programs-wrapper{height: 100%;}
    .programs-wrapper .v-spinner > .v-bounce.v-bounce1{top: 55%;}
</style>

<script>
import $ from 'jquery'
import carousel from 'vue-owl-carousel'
import Programs from './Programs.vue'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'

export default {
    name: 'DateSection',
    components: { carousel, Programs, BounceLoader },
    data(){
        return {
            dots: false,
            autoplay: false,
            nav: true,            
            owlActive: false,
            loading: false,
            allDates: [],
            programs: [],
            //baseurl: '',
            currentTime: '',
            fullPage: true,
            currentDate: '',
            responsive: {0:{items:5, margin: 2}, 600:{items:5, margin: 15}, 900:{items:7}},
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
            todayDate: ''
        }
    },
    methods: {
        getDates() {
            this.$http.get('dates')
            .then(res=>{
                if(res.status === 200 && res.data.status === true) {
                    this.allDates = res.data.results;
                    this.currentDate = res.data.current_date;
                    this.programs = res.data.contents;
                    this.currentTime = res.data.current_time;
                    this.owlActive = true;
                    this.todayDate = res.data.today;
                    // this.baseurl = res.data.base_url;
                } 
            });
        },
        showevents(timestamps) {
            $('.listing-wrapper').animate({scrollTop: 0}, "slow");
            this.loading = true;
            this.$http.get('getevents', {
                params: {
                    date: timestamps
                }
            })
            .then(res=>{
                if(res.status === 200 && res.data.status === true) {
                    this.currentDate = timestamps;
                    this.programs = res.data.results;
                    this.currentTime = res.data.current_time;
                    this.todayDate = res.data.today;
                    // this.baseurl = res.data.base_url;
                    this.loading = false;
                } 
            });
        }
    },
    mounted() {
        this.getDates()
    }
}
</script>